
<template>
    <div class='bg-gray-500 h-screen flex flex-wrap content-center justify-center'>    
        <Card class='w-1/4 min-w-min min-h-min' >
            <span>
                <a class='text-blue-500 text-xs' :href="casdoor">跳转至登录页面...</a>
            </span>
        </Card>
    </div>
</template>


<script>
export default {
    name: "login",
    props: {
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            redirect_uri:  '' ,
            dd_app_key: 'dingadxztnzp3b4s5vgf', //'dingw0ulvm31epklk10u',
            source_url: '', // 从哪个页面跳转来
        }
    },
    methods: {
        // 
        jumpToCasdoor(){
            // let self = this;
            let host = window.location.host;
            let casdoor = "https://auth.nana1024.com/login/oauth/authorize?client_id=0e888a3874083b922f28&response_type=code&redirect_uri=https://"+host+"/login/oauth2/callback?scope=read&state=casdoor"
            // console.log("host -> ", host);
            window.location.href = casdoor;
        },
        // end
    },
    mounted () {
        // 根据环境配置
        let self = this;
        // console.log("login/index.vue -> mounted");
        // 直接跳转到casdoor认证中心
        self.jumpToCasdoor();
        return
    },
    watch: {
    },
    computed: {}
}
</script>
